<template>
  <div>
    <b-card body-class="card-logs pt-1 p-0 m-0">
      <b-table v-if="!showHeader" :fields="fields" fixed small hover striped :busy.sync="isLoadingLogs" style="position: sticky;" class="rounded" />
      <div v-for="product in products" :key="product.id" class="rounded mt-0">
        <h5 v-if="product.itemsold" class="pt-1 pl-1"><strong> {{product.itemsold}}</strong></h5>
        <b-table
          :items="product.detailItem"
          :fields="fields"
          fixed
          small
          hover
          striped
          show-empty
          empty-text="Sin cambios"
          :busy.sync="isLoadingLogs"
          sticky-header="400px"
          :style="!showHeader ? 'border-top: black solid 1px;' : null"
          :thead-class="!showHeader ? 'd-none' : null"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
              <strong> Cargando...</strong>
            </div>
          </template>

          <!-- Orden -->

          <template #cell(sale)="row">
            <!-- <span v-if="row.item.sale.customer"><strong>Cliente: </strong>{{row.item.sale.customer}}<br></span> -->
            <span v-if="row.item.sale.saleusername"><strong>Vendedor: </strong>{{row.item.sale.saleusername}}<br></span>
            <span v-if="row.item.sale.totalSale"><strong>Total Venta: </strong>{{currency(row.item.sale.totalSale)}}<br></span>
            <span v-if="row.item.sale.hotel"><strong>Hotel: </strong>{{showHotel(row.item.sale.hotel)}}<br></span>
            <span v-if="row.item.sale.orderStatus"><strong>Estatus: </strong>{{showStatus(row.item.sale.orderStatus)}}<br></span>
          </template>

          <template #cell(payment)="row">
            <span v-if="row.item.payment.paymentdate"><strong>Fecha de Pago: </strong>{{row.item.payment.paymentdate}}<br></span>
            <span v-if="row.item.payment.paymentmethod"><strong>Método de Pago: </strong>{{showPaymentMethod(row.item.payment.paymentmethod)}}<br></span>
            <span v-if="row.item.payment.paymentreference"><strong>Referencia de Pago: </strong>{{row.item.payment.paymentreference}}<br></span>
            <span v-if="row.item.payment.paymentmethod2"><strong>Método de Pago 2: </strong>{{row.item.payment.paymentmethod2}}<br></span>
            <span v-if="row.item.payment.paymentreference2"><strong>Referencia de Pago: </strong>{{row.item.payment.paymentreference2}}<br></span>
            <span v-if="row.item.payment.customeragent"><strong>Referido: </strong>{{row.item.payment.customeragent}}<br></span>
            <span v-if="row.item.payment.firstpay"><strong>Primer Pago: </strong>{{currency(row.item.payment.firstpay)}}<br></span>
            <span v-if="row.item.payment.totalPaid"><strong>Total Pagado: </strong>{{currency(row.item.payment.totalPaid)}}<br></span>
            <span v-if="row.item.payment.balance"><strong>Balance: </strong>{{currency(row.item.payment.balance)}}<br></span>
          </template>

          <!-- Servicios -->

          <template #cell(notas)="row">
            <span v-if="row.item.notas.note"><strong>Nota Interna:<br></strong>{{row.item.notas.note}}<br></span>
            <span v-if="row.item.notas.clientnote"><strong>Nota Cliente:<br></strong>{{row.item.notas.clientnote}}<br></span>
          </template>

          <template #cell(operacion)="row">
            <span v-if="row.item.operacion.operationdate"><strong>Fecha de operación: </strong>{{row.item.operacion.operationdate}}<br></span>
            <span v-if="row.item.operacion.operationtime"><strong>Hora de operación: </strong>{{row.item.operacion.operationtime}}<br></span>
          </template>

          <template #cell(totales)="row">
            <span v-if="row.item.totales.total"><strong>Total: </strong>{{currency(row.item.totales.total)}}<br></span>
            <span v-if="row.item.totales.totalpay"><strong>Total Pagado: </strong>{{currency(row.item.totales.totalpay)}}<br></span>
            <span v-if="row.item.totales.unitPrice"><strong>Precio Unitario: </strong>{{currency(row.item.totales.unitPrice)}}<br></span>
            <span v-if="row.item.totales.netTotal"><strong>Total Neto: </strong>{{currency(row.item.totales.netTotal)}}<br></span>
            <span v-if="row.item.totales.firstpay"><strong>Primer Pago: </strong>{{currency(row.item.totales.firstpay)}}<br></span>
            <span v-if="row.item.totales.orderdetailStatus"><strong>Estatus del Detalle: </strong>{{showStatus(row.item.totales.orderdetailStatus)}}<br></span>
          </template>

          <!-- Reservas -->

          <template #cell(huesped)="row">
            <span>
              <strong>Huésped Principal: </strong>
              {{row.item.huesped.nameprefix}} {{row.item.huesped.givenname}} {{row.item.huesped.surname}}
            <br></span>
            <span v-if="row.item.huesped.email"><strong>Email: </strong>{{row.item.huesped.email}}<br></span>
            <span v-if="row.item.huesped.phonenumber"><strong>Teléfono: </strong>{{row.item.huesped.phonenumber}}<br></span>
          </template>

          <template #cell(booking)="row">
            <span v-if="row.item.booking.hotel"><strong>Hotel: </strong>{{showHotel(row.item.booking.hotel)}}<br></span>
            <span v-if="row.item.booking.datein"><strong>Check In: </strong>{{row.item.booking.datein}}<br></span>
            <span v-if="row.item.booking.dateout"><strong>Check Out: </strong>{{row.item.booking.dateout}}<br></span>
            <span v-if="row.item.booking.nights"><strong>Noches: </strong>{{row.item.booking.nights}}<br></span>
            <span v-if="row.item.booking.adults"><strong>Adultos: </strong>{{row.item.booking.adults}}<br></span>
            <span v-if="row.item.booking.children"><strong>Niños: </strong>{{row.item.booking.children}}<br></span>
          </template>


          <template #cell(datastatus)="row">
            <!-- <span v-if="row.item.datastatus.bookingstatus"><strong>Estatus: </strong>{{row.item.datastatus.bookingstatus}}<br></span> -->
            <span v-if="row.item.datastatus.reference"><strong>Referencia: </strong>{{row.item.datastatus.reference}}<br></span>
            <span v-if="row.item.datastatus.mealplancode"><strong>Plan de Alimentos: </strong>{{row.item.datastatus.mealplancode}}<br></span>
            <span v-if="row.item.datastatus.promotioncode"><strong>Código de Promoción: </strong>{{row.item.datastatus.promotioncode}}<br></span>
            <span v-if="row.item.datastatus.rateplancode"><strong>Rate Plan: </strong>{{row.item.datastatus.rateplancode}}<br></span>
            <span v-if="row.item.datastatus.roomtypename"><strong>Tipo de Habitación: </strong>{{row.item.datastatus.roomtypename}}<br></span>
            <span v-if="row.item.datastatus.netTotal"><strong>Total Neto: </strong>{{currency(row.item.datastatus.netTotal)}}<br></span>
          </template>

          <!-- Huéspedes -->

          <template #cell(adults)="row">
            <span v-if="row.item.adults.ismain"><strong>Huésped Principal: </strong><br></span>
            <span v-if="row.item.adults.name"><strong>Nombre: </strong>{{row.item.adults.name}}<br></span>
            <span v-if="row.item.adults.lastname"><strong>Apellido: </strong>{{row.item.adults.lastname}}<br></span>
            <span v-if="row.item.adults.address"><strong>Direccion: </strong>{{row.item.adults.address}}<br></span>
            <span v-if="row.item.adults.email"><strong>Email: </strong>{{row.item.adults.email}}<br></span>
            <span v-if="row.item.adults.phone"><strong>Teléfono: </strong>{{row.item.adults.phone}}<br></span>
          </template>

          <template #cell(childs)="row">
            <span v-if="row.item.childs.name"><strong>Nombre: </strong>{{row.item.childs.name}}<br></span>
            <span v-if="row.item.childs.lastname"><strong>Apellido: </strong>{{row.item.childs.lastname}}<br></span>
            <span v-if="row.item.childs.edad"><strong>Teléfono: </strong>{{row.item.childs.edad}}<br></span>
          </template>

          <!-- Itinerarios -->

          <template #cell(flight)="row">
            <span v-if="row.item.flight.flight"><strong>Vuelo: </strong>{{row.item.flight.flight}}<br></span>
            <span v-if="row.item.flight.flighttime"><strong>Hora de Vuelo: </strong>{{row.item.flight.flighttime}}<br></span>
            <span v-if="row.item.flight.pickup"><strong>Pick Up: </strong>{{row.item.flight.pickup}}<br></span>
            <span v-if="row.item.flight.typeflight"><strong>Tipo de Vuelo: </strong>{{row.item.flight.typeflight}}<br></span>
            <span v-if="row.item.flight.airline"><strong>Aerolinea: </strong>{{row.item.flight.airline}}<br></span>
          </template>

          <template #cell(service)="row">
            <span v-if="row.item.service.pax"><strong>№ Pax: </strong>{{row.item.service.pax}}<br></span>
            <span v-if="row.item.service.trip"><strong>Tipo de Servicio: </strong>{{row.item.service.trip}}<br></span>
            <span v-if="row.item.service.originPlaceDisplay"><strong>Origen: </strong>{{row.item.service.originPlaceDisplay}}<br></span>
            <span v-if="row.item.service.destinationPlaceDisplay"><strong>Destino: </strong>{{row.item.service.destinationPlaceDisplay}}<br></span>
          </template>

          <template #cell(beneficiary)="row">
            <span>Beneficiario: <br></span>
            <strong>{{row.item.beneficiary.name}} {{row.item.beneficiary.lastname}}</strong>
          </template>

          <!-- logs -->

          <template #cell(logs)="row">
            <span v-if="row.item.logs.logcreatedAt"><strong>Registrado el: <br></strong>{{formattime(row.item.logs.logcreatedAt)}}<br></span>
            <span v-if="row.item.logs.logcreatedBy"><strong>Registrado por: <br></strong>{{row.item.logs.logcreatedBy}}<br></span>
            <span v-if="row.item.logs.logoperation"><strong>Movimiento: </strong>{{row.item.logs.logoperation}}<br></span>
          </template>

        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { formatDateSpecific, toCurrency } from '@/helpers/helpers'

export default {
  props:{
    products:{
      type: Array,
      required: true
    },
    isLoadingLogs:{
      type: Boolean,
      required: false
    },
    fields:{
      type: Array,
      required: false
    },
    showHeader:{
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapState("start", ["hotels", "currencies", "status", "paymentMethods"]),
    ...mapState('mySales',['selectedOrder']),
  },
  methods: {
    showHotel(id){
      const hotel = this.hotels?.find(hotel=>hotel.id == id)
      return hotel?.name
    },
    currency(data){
      const actualCurrency = this.currencies.find((money)=> money.currencyid == this.selectedOrder.currency.id)
      if (this.selectedOrder.currencyExchangeRate) {
        return toCurrency(parseFloat(data) * parseFloat(this.selectedOrder.currencyExchangeRate), this.selectedOrder.currency.code) +' '+ this.selectedOrder.currency.code
      } else return toCurrency(parseFloat(data) * parseFloat(actualCurrency.value), actualCurrency.code) +' '+ actualCurrency.code
    },
    showStatus(id){
      const status = this.status?.find(status=>status.id == id)
      return status?.name
    },
    showPaymentMethod(id){
      const paymentmethod = this.paymentMethods?.find(pm => pm.id == id)
      return paymentmethod?.name
    },
    formattime(time){
      if (time){
        const dateString = time.replace('Z', '').split('T').join(' ')
        const format = 'YYYY-MM-DD HH:mm:ss'
        const toFormat = 'YYYY-MM-DD | hh:mm A'
        const date = formatDateSpecific({date: dateString, language:'es', format, toFormat})
        return date
      } else return ''
    }
  }
}
</script>
<style>
.card-logs{
  max-width: fit-content;
  overflow-x: auto !important;
}
</style>