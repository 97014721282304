<template>
    <div>                  
        <!-- <h5>{{product.itemsold}}</h5> -->
        <ValidationObserver ref="observerDateOperation">
            <b-row>
                <b-col md="12">
                    <ValidationProvider rules="required" name="Operation date" >
                        <b-form-group slot-scope="{ valid, errors }" label="Fecha de operación">                      
                            <flat-pickr
                            v-model="product.operationdate"
                            class="form-control"                  
                            :config="product.blackoutDates"                            
                            :state="errors[0] ? false : (valid ? true : null)"
                            />
                        </b-form-group>
                    </ValidationProvider> 
                    <div class="text-center mt-2 mb-2" v-if="isGettingUnavaliableDates">
                        <p class="text-warning">Obteniendo disponibilidad</p>
                        <b-spinner label="Loading..." variant="info" />
                    </div> 
                    <p>Cantidad: {{product.qty}}</p>
                </b-col>                    
            </b-row> 
        </ValidationObserver>                 
    </div>   
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { currentDate, toJson } from '@/helpers/helpers' 
import flatPickr from 'vue-flatpickr-component'

export default {
    props:{        
        product:{
            type: Object,
            required: true             
        }
    }, 
    components: {      
        flatPickr
    },
    data(){
        return {
            fechaActual: currentDate(),
            isSettindDateOperation: false,   
            isGettingUnavaliableDates: false,
        }
    },
    async mounted(){
        if(this.product.categoryname !== 'Transfer'){
            this.isGettingUnavaliableDates = true
            const dates = await this.fetchBlackoutDatesProduct({idP: this.product.productID, qty: this.product.qty, fini: this.fechaActual})
            dates.sort( (a,b) => { return new Date(a.date) - new Date(b.date) })   
            const blackoutDates = dates.map( d => d.date)
            const configDate = { productID: this.product.productID, blackoutDates , maxDate: '' }
            this.setBlackoutsDateProduct(configDate)        
            this.isGettingUnavaliableDates = false
        }
    },  
    methods: {    
        ...mapActions('shop',['fetchBlackoutDatesProduct']),
        ...mapMutations('mySales',['setBlackoutsDateProduct']),                                 
    }
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>